<template>
    <div class="home-box">
      <div>
        <div class="el-dialog-header-box">
          <div class="dialog-title-block"></div>
          <span class="dialog-title-text">抖音已退款订单单管理</span>
        </div>
        <el-divider />
      </div>

      <!-- 查询区域 -->
      <div class="top-query">
        <div class="top-query-left">
          <div class="demo-input-suffix">
            商家ID：
            <el-input placeholder="请输入商家ID" prefix-icon="el-icon-search" v-model="storeid" clearable></el-input>
          </div>
          <div class="demo-input-suffix">
            订单号：
            <el-input placeholder="请输入订单号" prefix-icon="el-icon-search" type="number" class="inputFund" v-model="order_id" clearable></el-input>
          </div>
          <div class="demo-input-suffix">
            卷码号：
            <el-input placeholder="请输入卷码号" prefix-icon="el-icon-search" type="number" class="inputFund" v-model="codes" clearable></el-input>
          </div>
          <div class="demo-input-suffix">
            日期：
            <el-date-picker
              v-model="datePick"
              align=“center”
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable>
            </el-date-picker>
          </div>
          <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
        </div>
  
        <div class="top-query-right"></div>
      </div>

      <!-- table区域-begin -->
      <div class="table-box">
        <el-table :data="tableData.data" stripe style="width: 100%" v-loading="loading">
          <el-table-column prop="id" label="订单ID" width="120">
          </el-table-column>
          <el-table-column prop="order_id" label="订单号" min-width="120">
          </el-table-column>
          <el-table-column prop="name" label="数量" min-width="120">
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间" min-width="120">
          </el-table-column>
          <el-table-column prop="action" label="状态" min-width="120">
            <template slot-scope="scope">
              <span>{{ actionObject[scope.row.action] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="pay_amount" label="支付金额" min-width="120">
          </el-table-column>
          <el-table-column prop="original_amount" label="原始金额" min-width="120">
          </el-table-column>
          <el-table-column prop="storename" label="商家" min-width="120">
          </el-table-column>
          <el-table-column prop="name" label="券码ID" min-width="120">
          </el-table-column>
          <el-table-column prop="is_settle" label="分账状态" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.is_settle">{{ isshenheOptions[scope.row.is_settle] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="时间" min-width="120">
          </el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" label="操作" min-width="160">
            <template slot-scope="scope">
              <el-button type="primary" plain size="small" @click="handleDetail(scope.row.id)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- table区域-end -->
  
      <!-- 分页区域-begin -->
      <div>
        <el-pagination
          class="pagination"
          background layout="prev, pager, next"
          :total="tableData.total"
          :page-size="tableData.per_page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <!-- 分页区域-end -->
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import { mapState, mapMutations } from "vuex";
  import axios from "axios";
  
  export default({
    name: "refundRecord",
    data() {
      return {
        storeid: '',
        order_id: '',
        codes: '',
        datePick: '',
        tableData: {},
        actionObject: {},
        isshenheOptions: [],
        loading: false,
        page: 1,
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;
      // this.getautoshow()
      // this.getInfo()
      // this.getechart();
      this.getRefundedOrder()
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1000);
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      getechart() {
        // 基于准备好的dom，初始化echarts实例
        var myChart = this.$echarts.init(document.getElementById("chart"));
        // 绘制图表
        myChart.setOption({
          title: {
            text: "核销券数",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            // prettier-ignore
            data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45'],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
            axisPointer: {
              snap: true,
            },
          },
          series: [
            {
              smooth: true,
              data: [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                12,
                33,
                222,
                333,
                123,
                233,
                112,
                212,
                444,
                333,
                1,
                11,
                22,
              ],
              type: "line",
            },
          ],
        });
      },
      getInfo() {
        axios
          .post("http://shunyu.jnwww.top/admin.php?action=setting&method=store", {
            is_get: 1,
            token: this.token,
          })
          .then((res) => {
            console.log(res);
            if (res.data.msg == "登录已过期") {
              localStorage.removeItem("user");
              this.$router.replace("/");
              return;
            }
            let data = {
              store: res.data.data.store_name,
              logo: res.data.data.store_logo,
            };
            this.setsetting(data);
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      },
      getautoshow() {
        axios
          .get(
            "http://shunyu.jnwww.top/admin.php?action=autoshow&method=ex_list",
            {
              params: {
                token: this.token,
              },
            }
          )
          .then((res) => {
            if (res.data.msg == "登录已过期") {
              localStorage.removeItem("user");
              this.$router.replace("/");
              return;
            }
            res.data.data.data.forEach((item) => {
              this.options.push({
                label: item.exhibition_title,
                value: item.exhibition_id,
              });
              this.value = res.data.data.data[0].exhibition_id;
              this.getList();
            });
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      },
      getList() {
        axios
          .post(
            "http://shunyu.jnwww.top/admin.php?action=autoshow&method=tk_list",
            {
              exhibition_ids: this.value,
              begin_time: this.date[0],
              end_time: this.date[1],
              page_idx: this.page,
              page_size: 10,
              token: this.token,
            }
          )
          .then((res) => {
            this.tableData = res.data.data.data;
            if (res.data.data.data.length == 0) {
              this.empty = this.page;
              this.$message.error("已无数据");
            } else {
              this.empty = 10000000000000000;
            }
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      },
      search() {
        console.log('查询');
        let data = {
          storeid: this.storeid,
          order_id: this.order_id,
          codes: this.codes,
          do_start: this.datePick[0],
          do_end: this.datePick[1],
          datePick: this.datePick,
          page: this.page
        }
        this.getRefundedOrder(data)
      },
      // 查看订单详情
      handleDetail(id) {
        this.$message({
          message: '跳转已退款记录详情页面',
        });
        this.$router.push({path:'paidOrders/ordersDetails',query: {id,type:4}})
        // this.$router.push({name:'ordersDetails',params: {}})
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page = val
        this.search()
      },
      // 获取已取消订单列表
      getRefundedOrder(data) {
        this.loading = true
        this.$api.getRefundedOrder({...data}).then(
          res => {
            if(res.code == 'success'){
              this.tableData = res.data.list
              this.actionObject = res.data.action
              this.isshenheOptions = res.data.isshenhe
              // this.typeOptions = Object.values(res.data.type)
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
    },
  });
  </script>
  
  <style scoped lang="less">
  @import "../assets/less/easyElement.less";
  </style>
  